export enum EnumPolicyType {
  None = 1,
  Lbi = 1,
  Lci = 2,
  Lbc = 3,
  Tci = 5,
  Rg = 7,
  Tli = 8,
  Mi = 9,
  Lpi = 10,
  Rli = 11,
  Tip = 12,
}

export enum PaymentMethod {
  CreditDebit = 1,
  DirectDebit = 2,
  DirectDebitMonthly = 3,
}

export enum PaymentType {
  BuyPolicy = 1,
  Cancel = 2,
  Mta = 3,
  Renewal = 4,
  Endorsement = 5,
}


export enum BankTransaction {
  Debit = 1,
  Credit = 2
}
export enum BankTransactionType {
  BankFee = 1,
  Other = 2
}
export enum BankAccountType {
  ClientAccount = 1,
  OfficeAccount = 2,
  DepositAccount = 3
}

export enum ResponseCodes {
  Success = 0,
  Failed = 1,
  Processing = 3,
}

export enum EnumPolicyClaimStatus {
  Open = 1,
  Closed = 2,
  PendingApproval = 3,
  Declined = 4,
  Referred = 5,
  UnSubmitted = 6,
}
export enum PolicyStatus {
  None = 0,
  Quickquote = 1,
  Basicquote = 2,
  Finalquote = 3,
  Active = 4,
  Renewed = 5,
  Cancelled = 6,
  Mta = 7,
  Invited = 8,
  Lapsed = 9,
  Headline = 10,
  Monthlypolicy = 11,
  Default = 12,
  Suspended = 13,
  Referred = 14,
  Declined = 15,
  RenewalProceed = 16,
}
export enum InitPolicyStatus {
  None = 0,
  Quote = 1,
  Incepted = 2,
  Policy = 3,
  Cancelled = 4,
  Lapsed = 5,
  Declined = 6,
  MTA = 7,
}
export enum PolicySubStatus {
  None = 0,
  Active = 1,
  Renewed = 2,
  Cancelled = 3,
  MtaPending = 4,
  Decline = 5,
  Lapsed = 6,
  MTA = 7,
  RenewalReferred = 8,
  RenewalDeclined = 9,
  RenewalProceed = 10,
  CancelRequested = 11,
  CancelScheduled = 12,
  MTAReferred = 13,
  QuoteReferred = 14,
  NTU = 15,
  QuoteClosed = 16,
  NextVersionCreated = 17,
  MTAQuoteClosed = 18,
  RENQuoteClosed = 19,
}
export enum PolicyTransactionType {
  None = 0,
  NB = 1,
  MTA = 2,
  REN = 3,
  CAN = 4,
  NTU = 5,
}

export enum ContactType {
  MainContact = 1,
  AccountsContact = 2,
  ComplianceContact = 3,
  HandlerContact = 4
}

export enum Systems {
  None = 0,
  TenantLead = 1,
  Referencing = 2,
  Insurance = 4,
  RentGuarantee = 8,
  Finance = 16,
  NilDeposit = 32,
  Utilities = 64,
  RentonTime = 128,
  CRM = 256,
  TenantPortal = 512,
  LandlordPortal = 1024,
  ManagmentSystem = 2048,
  AllSystem = 32768,
}


export enum DocumentConfigType {
  Mandatory = 1,
  PreTenancy = 2,
  SelfBilling = 3,
  GI = 4,
  RTR = 5,
  LegacyRG = 6,
  VPlusRG = 7,
  LegacyReferencing = 8,
  VPlusReferencing = 9,
  CheckAndSubmit = 10,
  CompleteNow = 11,
  DisableINS = 12,
  IDValidation = 13,
  MediaOutPut = 14,
  ClaimWindow = 15,
  DirecttoLandlord = 16
}
export enum EnumAgentStatus {
  Active = 1,
  InActive = 2,
  InProgress = 3,
  All = 0,
}
export enum EntityType {
  SoleTrader = 1,
  Partnership = 2,
  LimitedCompany = 3,
  DAC = 4,
  BranchOffice = 5
}
export enum SpecialTermType {
  Temporary = 1,
  Permanent = 2
}
export enum NoteType {
  Policy = 1,
  DeclinePolicy = 2,
  NonRenewable = 4,
}

export enum ClaimStatusEnum {
  Open = 1,
  Closed = 2,
  ReOpened = 3,
}

export enum DiaryType {
  Refer = 1,
  Note = 2
}
export enum BusinessDescription {
  PublicHouse = 1,
  Restaurant = 2,
  Café = 3
}
export enum ProductType {
  Bbg = 1,
  Fcc = 2,
  Car = 4,
  ELPL = 5,
  Off = 6,
  Pa = 7,
  Pi = 8,
  Poi = 9,
  Lei = 10,
  Sb = 11,
  Shop = 12,
  Rei = 13,
  Clea = 14,
}

export enum PolicyNamedPrincipalType  //------ policy Named Principal Type for make common with note of interest
{
  PolicyNamedPrincipal = 1,
  NoteOfInterest = 2,
}
export enum SumInsured {
  buildingsSumInsured = 0,
  landlordsSumInsured = 1,
  landlordNotSumInsured = 2
}

export enum BordereauReportTypeEnum {
  PoiBbg_RM = 1,
  XlcCarSbCar_RM = 2,
  XlcCarSbCar_RB = 3,
  SB_ELPL_HCC_RM = 4,
  SB_ELPL_HCC_RB = 5,
  PA_RM = 6,
  PA_RB = 7,
  Contractors_All_Risks = 8,
  CLE_AmTrust = 9,
  Faughnan_Property_Owners = 10,
  PI_HCC = 11,
  QBE_CL_RB = 12,
  QBE_CL_RM = 13,
  QBE_CC_ELPL_RB = 14,
  QBE_CC_ELPL_RM = 15,
  QBE_CC_PROP_RB = 16,
  QBE_CC_PROP_RM = 17,
  QBE_REI_RB = 18,
  QBE_REI_RM = 19,
  PoiBbg_RB = 20
}